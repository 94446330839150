/*------------------------------------
  Transparent Header
------------------------------------*/

.dv-header--bg-transparent {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      &#{$infix} {
  			&:not(.js-header-fix-moment) {
  	      @include media-breakpoint-up($breakpoint) {
  	        .dv-search-push-top[style*="display: block"] {
  	        	+ .dv-header__section {
  	        		background-color: $header-bg;
  	        	}
  	        }

  	        .dv-header__section {
  						background-color: transparent;
  						box-shadow: none;
  	        }
          }
        }

        .dv-header__navbar-brand-collapsed,
        .dv-header__navbar-brand-on-scroll {
          &,
          .dv-header__navbar-brand-text {
            display: none;
          }
        }

        &.js-header-fix-moment {
          .dv-header__navbar-brand-default {
            &,
            .dv-header__navbar-brand-text {
              display: none;
            }
          }

          .dv-header__navbar-brand-on-scroll {
            display: flex;

            .dv-header__navbar-brand-text {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

.dv-header--bg-transparent {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        .dv-header__navbar-brand-default,
        .dv-header__navbar-brand-on-scroll {
          display: none;
        }
      }
    }
  }
}

.dv-header--bg-transparent {
  .dv-header__navbar-brand-default {
    display: flex;
  }

  @include media-breakpoint-down(sm) {
	  .dv-header__navbar-nav {
			background-color: $header-bg;
		}

  	.dv-header__navbar-nav {
	  	padding: $header-transparent-navbar-nav-padding;
	  	padding-top: 1rem;
	  }
  }
}