/*------------------------------------
  Text Colors
------------------------------------*/

.text-white-70 {
	color: $white-color-70;

	&[href]:hover {
		color: $white-color-hover;
	}
}

.text-green {
	color: $primary;
}

.icon-green {
    color: #7ec12d !important;
}
.bg-icon-green {
	background:#7ec12d !important;
	background-color: #7ec12d !important;
}
.border-icon-green {
	border:1px solid #7ec12d !important;
		border-color:#7ec12d !important;
}
.text-orange {
	color: $orange;
}
