.t-dark {
  color: $primary-darker !important;

}
.t-light {
  color: $primary-lighter !important;
}




h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
  color:#151515 !important;
}

.text-white {
  color: #ffffff !important;
}

h3 {
  color: $primary !important;

}
