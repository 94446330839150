/*------------------------------------
  File Attachment Input
------------------------------------*/

.file-attachment-input {
  position: relative;
  display: block;
  background-color: $gray-200;
  border: 2px dashed $gray-300;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  @include border-radius($border-radius);
  padding: 4rem;
  margin-bottom: 0;

  &__label {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    min-width: 100%;
    min-height: 100%;
    cursor: inherit;
    opacity: 0;
  }

  &:hover {
    background-color: darken($gray-200, .75%);
  }
}