/*------------------------------------
  Divider Sizes
------------------------------------*/

/* Extra Small */
.dv-divider {
  &--xs {
    font-size: ($font-size-base * .75);

    &::before,
    &::after {
      width: ($font-size-base * 2);
      height: ($font-size-base * .0625);
      transform: translateY(-50%);
    }

    &::before {
      margin-right: .625rem;
    }

    &::after {
      margin-left: .625rem;
    }
  }
}

.dv-divider--xs {
  &::before,
  &::after {
    width: ($font-size-base * 2);
  }
}