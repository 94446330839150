.wp-block {
    width: 90% !important;
    max-width : 100% !important;
}

.wp-block {
  min-width: 810px;
  max-width: 1200px; }
  .wp-block[data-align="wide"] {
    max-width: 1100px; }
  .wp-block[data-align="full"] {
    max-width: none; }

.acf-button-group {
  width:100%;

  label {
    text-align: left !important;
  }
}
