/*------------------------------------
  Header Navbar Background Colors
------------------------------------*/

.dv-header--navbar-bg {
	.dv-header__navbar-brand-text {
		color: $white;
	}

	.dv-header__nav-item {
	  &:hover {
	    .dv-header__nav-link {
				color: $white;
	    }
	  }
	}

  .active > {
  	.dv-header__nav-link {
			color: $white;
  	}
  }

	.dv-header__nav-link {
		color: rgba($white, .7);

	  &:hover {
			color: $white;
	  }
	}

	.dv-header__navbar-link,
	.dropdown-nav-link,
	.btn-text-secondary {
		color: rgba($white, .6);

		&:hover {
			color: $white;
		}
	}

  .btn-text-secondary {
    &[href]:hover {
      color: $white;
    }
  }

	.dv-hamburger {
    &__inner {
	    &,
	    &::before,
	    &::after {
	      background-color: rgba($white, .5);
	    }
	  }

		&:hover {
  		.dv-hamburger__inner {
	      &,
	      &::before,
	      &::after {
	        background-color: $white;
    		}
    	}
    }
	}
}