.section-why-bc {
    background: #00b6c3 url(/app/themes/divlab-qbs/dist/images/Hero_1600x600.png) 100% 100%;
    background-size: 1200px auto;
    background-repeat: no-repeat;
}

.bg-fractal {

background-image: url('/app/themes/divlab-qbs/dist/images/bg-fractal.jpg') !important ;
background-position: center;
background-size:cover;
background-repeat:no-repeat;

}

// ul {
//   list-style: none; /* Remove default bullets */
// }
ul li {
      line-height: 2;
}

ul li  {
  content: "";
}

.green-dots li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #00b7c3!important; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: .8em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

// body {
//   color:#77838f !important;
// }

.bg-green {
 background:  $primary-darker;
 background-color:  $primary-darker;
}


.line-height-title {
  line-height: 1.2em !important;
}


.support-block {
  .btn {

  @extend .btn-block;
}
}

.admin-bar {

  header {
  padding-top:40px;
}
}
