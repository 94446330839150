/*------------------------------------
  Header Collapse
------------------------------------*/

.dv-header-collapse {
	// Nav
	&__nav {
	  list-style: none;
		padding-left: 0;
	  margin-bottom: 0;

		&-list {
			padding-left: 0;
	  	list-style: none;
	  	margin-bottom: 0;
		}

		&-link {
			color: $dark;
		}

		&-pointer {
	  	position: relative;

			&::after {
				display: inline-block;
	      font-family: $font-family-font-awesome-icon;
	      font-size: 65%;
	      font-weight: 900;
	      content: "\f107";
	      margin-left: auto;
	      transition: $header-nav-link-icon-transition;
	  	}

			&.active {
				&::after {
					transform: rotate(-180deg);
				}
			}
	  }
	}

  &__nav-link,
  &__submenu-nav-link {
  	display: flex;
		align-items: center;
		padding-top: .625rem;
		padding-bottom: .625rem;

		&.active {
			color: $primary;
		}
	}

	// Sub Menu
	&__submenu {
	  position: relative;
		transition: $transition-timing $transition-function;

		.dv-header-collapse__nav-list {
			border-left: 2px solid $primary;
			padding-left: 1rem;

			&--no-spacer {
				padding-left: 0;
			}
		}

		&-nav-link {
  		color: $gray-700;
	  }

		&-list {
		  list-style: none;
			border-left: 2px solid $primary;
			padding-left: 0;
		  margin-bottom: 0;

			&-link {
		  	display: inline-block;
	  		color: $gray-700;
		  }
		}
  }
}
