/*------------------------------------
  Breadcrumb
------------------------------------*/

.breadcrumbs  {
  a {
    color:$breadcrumb-divider-color;


  }

  a:hover,
  a:active {
    color:$primary-darker;

  }

  .current-item {
    color:$primary-darker;

  }
}
.breadcrumb-white {
  background-color: $breadcrumb-bg;

  .breadcrumb-item {
    color: $white;

    + .breadcrumb-item::before {
      color: $white-color-70;
    }
  }

  .breadcrumb-link {
    color: $white-color-70;
  }
}

.breadcrumb-no-gutter {
  padding: 0;
}
