.bg-grey {
  background: #F8F9F9  !important;
  background-color: #F8F9F9 !important;

}

.bg-red {
  background:$red !important;
  background-color: $red !important;

}

.shadow-card {
  background: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
  border: 1px solid #fff;


}

.shadow-filter-card {
  background: #FFFFFF;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.20);
}
