/*------------------------------------
  Background Overlay Gradients
------------------------------------*/

[class*="gradient-overlay-half"] {
	position: relative;
	z-index: 1;

	&::before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		right: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		content: "";
	}
}

/*------------------------------------
  Background Overlay Gradients
------------------------------------*/


.green-gradient-overlay-form {
    background: linear-gradient(0deg,hsla(195,8%,46%,.08) 4%,hsla(0,0%,100%,.36) 77%);
}

.green-gradient-overlay:before {
    z-index: 2;
    background-image: linear-gradient(-179deg,hsla(195,8%,46%,.08) 2%,hsla(0,0%,100%,.16));
}

.green-overlay {
	&::before {
		z-index: 2;
    background: rgba(14,51,57,.75);
	}
}
