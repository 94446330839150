/*------------------------------------
  Header Helpers
------------------------------------*/

.dv-header {
  &--invisible {
    display: none;
  }

  &--moved-up {
    transform: translate3d(0, -100%, 0);
  }

  &--faded {
    opacity: 0;
    visibility: hidden;
  }

  &__section--hidden {
    position: relative;
  }

  &[data-header-fix-effect] {
    transition: $header-fix-effect-transition;
  }

  &.dv-header--untransitioned {
    transition: none;
  }

  &.js-header-fix-moment {
    position: fixed;
    top: 0;
    bottom: auto;
  }

  &--fix-top {
    position: fixed;
  }

  &.dv-header--fix-top[data-effect-compensation] {
    transition: none;
  }
}
