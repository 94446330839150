/*------------------------------------
  Skippy
------------------------------------*/

@if $enable-accessibility {
  .dv-skippy {
    display: block;
    color: $white;
    background-color: theme-color("primary");
    padding: 1em;
    outline: 0;
    transition: all .2s ease-in;

    &:hover {
      color: $white;
    }

    // fix header absolute overlapping skippy content
    &:focus {
      + .dv-header--abs-top {
        &:not(.dv-header--moved-up):not(.dv-scrolled),
        &-sm:not(.dv-header--moved-up):not(.dv-scrolled),
        &-md:not(.dv-header--moved-up):not(.dv-scrolled),
        &-lg:not(.dv-header--moved-up):not(.dv-scrolled),
        &-xl:not(.dv-header--moved-up):not(.dv-scrolled) {
          margin-top: 3.5rem;
        }
      }

      + .dv-header--sticky-top {
        &:not(.dv-header--moved-up):not(.dv-scrolled),
        &-sm:not(.dv-header--moved-up):not(.dv-scrolled),
        &-md:not(.dv-header--moved-up):not(.dv-scrolled),
        &-lg:not(.dv-header--moved-up):not(.dv-scrolled),
        &-xl:not(.dv-header--moved-up):not(.dv-scrolled) {
          margin-top: 3.5rem;
        }
      }
    }

    .dv-skiplink-text {
      padding: .5em;
      outline: 1px dotted;

      &:hover {
        color: $white;
      }
    }
  }
} @else {
  .dv-skippy {
    display: none;
  }
}