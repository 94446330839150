.navbar-top-bg {
  background: #F9FBFD;
  background-color: #F9FBFD;
}
.navbar-shadow {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.20);

}

.menu-item {
  @extend .pl-2;
}





  // Extra small devices (portrait phones, less than 576px)
  // No media query for `xs` since this is the default in Bootstrap

  // Small devices (landscape phones, 576px and up)
  @media (max-width: 576px) {
    body {
        padding-top:54px;
    }
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {

    body {
        padding-top:86px;
    }
   }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    body {
        padding-top:86px;
    }


  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {

    body {
        padding-top:126px;
    }


   }
