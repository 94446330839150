/*------------------------------------
  Search Form - Push Top
------------------------------------*/

.dv-search-push-top {
	display: none;
	background-color: $search-push-top-bg;
	padding-top: $search-push-top-padding-paddingY;
	padding-bottom: $search-push-top-padding-paddingY;
	box-shadow: $search-push-top-box-shadow;

	&__content {
		max-width: $search-push-top-inner-max-width;
		margin-left: auto;
		margin-right: auto;
	}

	&__close-btn {
		position: absolute;
		top: -1.5625rem;
		right: .4375rem;
	}

	// Small Devices
	@include media-breakpoint-down(xs) {
		padding-top: $search-push-top-padding-paddingY-sm;
		padding-bottom: $search-push-top-padding-paddingY-sm;
	}
}

/* Banner */
.dv-search-push-top__banner {
	background-color: $search-push-top-banner-bg;
	box-shadow: $search-push-top-banner-box-shadow;
	padding-right: $search-push-top-banner-padding-right;
	padding-top: $search-push-top-banner-padding-top;
	overflow: hidden;

	&-container {
		position: relative;
		width: 75%;
		min-height: 11.5625rem;
	}

	&-img {
		position: absolute;
		bottom: -.625rem;
		left: -.625rem;
		transition: all .3s ease-in-out;
	}

	&:hover {
		.dv-search-push-top__banner-img {
			&:first-child {
				bottom: 0;
				left: 0;
			}
		}
	}
}