/*------------------------------------
  Header Center Aligned
------------------------------------*/

.dv-header--center-aligned {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint) {
      &#{$infix} {
        .dv-hamburger {
          display: none;
        }

        .dv-header--center-aligned__inner {
          justify-content: center;
          text-align: center;
          padding-top: .5rem;
        }

        .dv-header__navbar-brand {
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
  }
}

.dv-header--center-aligned {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        .dv-header--center-aligned__inner {
          display: flex;
          justify-content: space-between;
          padding-top: $header-navbar-paddingY;
        }

        .dv-header__navbar {
          padding-top: 0;
        }
      }
    }
  }
}