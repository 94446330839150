/*------------------------------------
  Header White Nav Links
------------------------------------*/

.dv-header--white-nav-links {
	@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    &#{$infix} {
			&:not(.bg-white):not(.js-header-fix-moment) {
				@include media-breakpoint-up($breakpoint) {
					.dv-header__nav-item {
					  &:hover {
					    .dv-header__nav-link {
								color: rgba($white, 1);
					    }
					  }
					}

					.dv-header__nav-link {
						color: rgba($white, .7);

					  &:hover {
							color: rgba($white, 1);
					  }
					}

					.dv-header__navbar-link,
					.dropdown-nav-link,
					.btn-text-secondary {
						color: rgba($white, .6);

						&:hover {
							color: rgba($white, 1);
						}
					}
				}
			}
		}
	}
}